/* eslint-disable import/order */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import './index.css'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { API, Auth, } from 'aws-amplify'
import ReactHtmlParser from 'react-html-parser'
import { Editor } from '@tinymce/tinymce-react'
import { Transition } from '@headlessui/react'
import { cloneDeep } from 'lodash'

const qaApi = 'questionresponse'

const QaContent = ({ comment, handleEditorChange, idx, handleCheckbox }) => {
  const [editorVisible, setEditorVisible] = useState(false)
  return <div key={comment.comment} className="border my-5">
    <div>
      <span className="font-bold">From</span> <br />Name: {comment.name}<br />
      Email: {comment.email}
    </div>
    <div className={`mt-4 space-y-6 text-sm text-gray-800 ${comment.published ? 'bg-blue-50' : 'bg-red-300'}`}>
      {ReactHtmlParser(comment.comment)}
    </div>
    <div className="mt-4">

      <div>
        {comment.published ?
          <>
            <svg className="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 24 24" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
            </svg>
            <span>Published</span></> :
          <>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-800" fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <span>Not Published</span>

          </>}
        <button
          onClick={() => setEditorVisible(!editorVisible)}
          className="ml-6 inline-flex items-center justify-center px-1 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Show Editor
        </button>
      </div>

      {editorVisible ? <>

          <label>
            <input
              className="mr-2"
              defaultChecked={comment.published}
              key={comment.comment}
              name={comment.comment}
              onChange={() => handleCheckbox(idx)}
              type="checkbox"
            />
            Published
          </label>
          <Editor
            textareaName="comment"
            value={comment.response}
            className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
            apiKey="8la042sb0kwnbrsnjl63vq6av0mw2pxdwz0vfith1jey9e9r"
            init={{
              height: 400,
              menubar: false,
              branding: false,
              extended_valid_elements: 'iframe[src|width|height|name|id|class|align|style|frameborder|border|allowtransparency|allowfullscreen|allow|autoplay|clipboard-write|encrypted-media|gyroscope|picture-in-picture]',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | code | \
                bullist numlist outdent indent | removeformat | help',
              directionality: 'ltr'
            }}
            onEditorChange={(response) => handleEditorChange(idx, response)}
          />
        </>
        : null}

    </div>
  </div>
}

const Accordion = ({ item }) => {
  const [isOpen, setOpen] = useState(false)
  const [itemCache, setItemCache] = useState(item)
  const published = item.comments.filter(comm => comm.published).length
  const unpublished = item.comments.filter(comm => !comm.published).length
  const saveResponse = async () => {
    const resp = await API.post(qaApi, `/qa/`, {
      body: itemCache
    })
    console.log(resp)
  }
  const handleEditorChange = (commentIndex, response) => {
    console.log(response)

    const temp = cloneDeep(itemCache)
    temp.comments[commentIndex].response = response
    setItemCache(temp)
  }
  const handleCheckbox = (commentIndex) => {
    const temp = cloneDeep(itemCache)
    temp.comments[commentIndex].published = !temp.comments[commentIndex].published
    setItemCache(temp)
  }

  return (
    <div className="mt-6 border-t border-gray-200 pt-6">

      <div>
        <dt className="text-lg leading-7">
          <button onClick={() => setOpen(!isOpen)}
                  aria-label="Show answer"
                  className="text-left w-full font-bold flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                      <span className=" prose prose-indigo prose-lg text-gray-700">
                          {`${item.resource}`}<span
                        className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">{`${published} published`}</span>
                        {unpublished > 0 ? <span
                          className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">{`${unpublished} unpublished`}</span> : null}
                      </span>
            <span className="ml-6 h-7 flex items-center">

                            <svg className={`${isOpen ? '-rotate-180' : 'rotate-0'} h-6 w-6 transform`}
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </span>
          </button>
        </dt>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 -translate-y-2"
          enterTo="transform opacity-100 translate-y-0"
          leave="transition ease-in duration-100"
          leaveFrom="transform opacity-100 translate-y-2"
          leaveTo="transform opacity-0 -translate-y-2"
        >
          {(ref) => (
            <dd ref={ref} className="mt-2 pr-12">
              {itemCache.comments.map((comment, idx) => {

                return <QaContent key={idx} idx={idx} comment={comment}
                                  handleEditorChange={handleEditorChange}
                                  saveResponse={saveResponse}
                                  handleCheckbox={handleCheckbox} />
              })
              }

              <button
                onClick={() => saveResponse()}
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Save all responses for {item.resource}
              </button>
            </dd>)}
        </Transition>
      </div>
    </div>
  )
}

const Admin = () => {

  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        const groupsResponse = data.idToken.payload['cognito:groups']
        if (groupsResponse) {
          setGroups(groupsResponse)
        }
      })
  }, [])
  useEffect(() => {
    const getItems = async () => {
      const resp = await API.get(qaApi, `/qa/`)
      setItems(resp.items)
    }
    getItems()
  }, [])

  if (groups.includes('Admin')) {
    return (
      <div className="container ml-10">
        {items.map(item => <Accordion key={item.resource} item={item} />)}
      </div>
    )
  }
  return (
    <div>
      Not Authenticated
    </div>
  )
}

export default withAuthenticator(Admin, false)
